import React from "react";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import Sidebar from "../../components/Sidebar";
const StatesWrapper = styled.div`
  padding: 60px;
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
  border: 1px solid lightgray;
  padding: 8px 16px;
`;

const Span1 = styled.div`
  font-size: 20px;
  font-weight: 700;
`;
const Span2 = styled.span`
  color: grey;
  font-size: 14px;
`;
const Span3 = styled.span`
  color: grey;
  font-size: 14px;
  font-weight: 700;
`;
const Span4 = styled.span`
  font-size: 14px;
  color: blue;
`;
const Span5 = styled.span`
  color: blue;
  font-weight: 700;
  font-size: 16px;
  background-color: lightcyan;
`;

const States = () => {
  return (
    <StatesWrapper>
      <FlexDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "grey",
          }}
        >
          <UserOutlined style={{ color: "white", fontSize: 32, padding: 4 }} />
        </div>
        <div>
          <Span1>Triggered via UI</Span1>
          <div>
            <Span2>#sv-dcqC9xwjs4b5M987 | </Span2>
            <Span3>alexey-digger </Span3>
            <Span2>triggered from Terraform 5 months ago | </Span2>
            <Span4>#run-hB7HtPiMYjbMuEhh | </Span4>
            <Span5>65f89be</Span5>
          </div>
        </div>
      </FlexDiv>
      <FlexDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "grey",
          }}
        >
          <UserOutlined style={{ color: "white", fontSize: 32, padding: 4 }} />
        </div>
        <div>
          <Span1>Triggered via UI</Span1>
          <div>
            <Span2>#sv-dcqC9xwjs4b5M987 | </Span2>
            <Span3>alexey-digger </Span3>
            <Span2>triggered from Terraform 5 months ago | </Span2>
            <Span4>#run-hB7HtPiMYjbMuEhh | </Span4>
            <Span5>65f89be</Span5>
          </div>
        </div>
      </FlexDiv>
      <FlexDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "grey",
          }}
        >
          <UserOutlined style={{ color: "white", fontSize: 32, padding: 4 }} />
        </div>
        <div>
          <Span1>Triggered via UI</Span1>
          <div>
            <Span2>#sv-dcqC9xwjs4b5M987 | </Span2>
            <Span3>alexey-digger </Span3>
            <Span2>triggered from Terraform 5 months ago | </Span2>
            <Span4>#run-hB7HtPiMYjbMuEhh | </Span4>
            <Span5>65f89be</Span5>
          </div>
        </div>
      </FlexDiv>
      <FlexDiv>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "grey",
          }}
        >
          <UserOutlined style={{ color: "white", fontSize: 32, padding: 4 }} />
        </div>
        <div>
          <Span1>Triggered via UI</Span1>
          <div>
            <Span2>#sv-dcqC9xwjs4b5M987 | </Span2>
            <Span3>alexey-digger </Span3>
            <Span2>triggered from Terraform 5 months ago | </Span2>
            <Span4>#run-hB7HtPiMYjbMuEhh | </Span4>
            <Span5>65f89be</Span5>
          </div>
        </div>
      </FlexDiv>
    </StatesWrapper>
  );
};

export default States;
