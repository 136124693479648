import React from "react";
import styled from "styled-components";
import { Menu } from "antd";
import { useLocation, Link } from "react-router-dom";
import {
  UserOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  FolderOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import Logo from "./Logo";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
`;

const HeaderIcons = styled.div`
  display: flex;
  gap: 16px;
`;

const MainLink = styled(Link)`
  text-decoration: none !important;
`;

const Main = (location) => {
  if (location === "/digger/projects") {
    const items = [
      {
        label: "Manage",
        key: "manage",
        disabled: true,
      },
      {
        label: "Projects",
        key: "projects",
        icon: <FolderOutlined />,
      },
      {
        label: "Registry (coming soon)",
        key: "registry",
        icon: <AppstoreOutlined />,
      },
      {
        label: "Visibility",
        key: "visibility",
        disabled: true,
      },
      {
        label: "Explorer",
        key: "settings",
        icon: <SettingOutlined />,
      },
      // {
      //   label: "Cloud Platform",
      //   key: "cloud-platform",
      //   disabled: true,
      // },
      // {
      //   label: "HashiCorp Cloud Platform",
      //   key: "hashicorp",
      //   icon: <SettingOutlined />,
      // },
    ];
    return (
      <Menu
        theme="dark"
        defaultSelectedKeys={["projects"]}
        mode="inline"
        items={items}
      />
    );
  } else {
    const name = location.split("/")[3];
    const items = [
      {
        label: name,
        key: name,
        disabled: true,
      },
      {
        label: (
          <MainLink to={`digger/projects/${name}/overview`}>Overview</MainLink>
        ),
        key: "overview",
      },
      {
        label: <MainLink to={`digger/projects/${name}/runs`}>Runs</MainLink>,
        key: "runs",
      },
      {
        label: (
          <MainLink to={`digger/projects/${name}/states`}>States</MainLink>
        ),
        key: "states",
      },
      {
        label: (
          <MainLink to={`digger/projects/${name}/variables`}>
            Variables
          </MainLink>
        ),
        key: "variables",
      },
    ];
    return (
      <Menu
        theme="dark"
        defaultSelectedKeys={["overview"]}
        mode="inline"
        items={items}
      />
    );
  }
};

const Sidebar = () => {
  const location = useLocation().pathname;
  return (
    <div>
      <Header>
        <a href="/">
          <Logo />
        </a>
        <HeaderIcons>
          <SearchOutlined />
          <QuestionCircleOutlined />
          <UserOutlined />
        </HeaderIcons>
      </Header>
      <div>{Main(location)}</div>
    </div>
  );
};

export default Sidebar;
