import { Typography, Table } from "antd";
import React from "react";

const { Text } = Typography;
const columns = [
  {
    title: "Key",
    dataIndex: "unique",
    key: "unique",
  },
  {
    title: "Value",
    dataIndex: "Value",
    key: "Value",
    responsive: ["md"],
  },
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    responsive: ["lg"],
  },
  {
    title: "",
    dataIndex: "other",
    key: "other",
  },
];
const data = [
  {
    key: "1",
    unique: "env_name",
    Value: "staging",
    Category: "terraform",
    other: "...",
  },
  {
    key: "2",
    unique: (
      <div>
        secrets <Text code>HCL</Text>
        <br />
        <Text code>SENSITIVE</Text>
      </div>
    ),
    Value: <i>Sensitive-write only</i>,
    Category: "terraform",
    other: "...",
  },
  {
    key: "3",
    unique: "subdomain_name",
    Value: "staging",
    Category: "terraform",
    other: "...",
  },
  {
    key: "4",
    unique: "workspace_name",
    Value: "staging",
    Category: "terraform",
    other: "...",
  },
  {
    key: "5",
    unique: "AWS_ACCESS_KEY_ID",
    Value: "AKIAZ6ABWDJFC62ARKOJ",
    Category: "env",
    other: "...",
  },
  {
    key: "6",
    unique: "AWS_DEFAULT_REGION",
    Value: "us-east-1",
    Category: "env",
    other: "...",
  },
  {
    key: "7",
    unique: (
      <div>
        AWS_SECRET_ACCESS_KEY
        <br />
        <Text code> SENSITIVE</Text>
      </div>
    ),
    Value: <i>Sensitive-write only</i>,
    Category: "env",
    other: "...",
  },
];
const App = () => {
  return (
    <div style={{ margin: "0 60px" }}>
      <div>
        <h1 style={{ fontWeight: "bold" }}>Variables</h1>
        <p>
          Terraform uses all <a style={{ color: "blue" }}>Terraform</a> and{" "}
          <a style={{ color: "blue" }}>Environment</a> variables for all plans
          and applies in this workspace. Workspaces using Terraform 0.10.0 or
          later can also load default values from any{" "}
          <Text code style={{ fontWeight: "bold" }}>
            *.auto,tfvars
          </Text>{" "}
          files in the configuration. You may want to use the Terraform Cloud
          Provider or the variables API to add multiple variables at once.
        </p>
        <h1 style={{ fontWeight: "bold" }}>Sensitive variables</h1>
        <p>
          <a style={{ color: "blue" }}>Sensitive</a> variables are never shown
          in the UI or API, and can't be edited. They may appear in Terraform
          logs if your configuration is designed to output them. To change a
          sensitive variable, delete and replace it.
        </p>
        <h1 style={{ fontWeight: "bold" }}>Workspace variables (7)</h1>
        <p>
          Variables defined within a workspace always overwrite variables from
          variable sets that have the same type and the same key. Learn more
          about variable set <a style={{ color: "blue" }}>precedence</a>
        </p>
      </div>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
};
export default App;
