import React from "react";
import { Select, Button, Tabs } from "antd";
import styled from "styled-components";
import { CheckOutlined } from "@ant-design/icons";

const RunsWrapper = styled.div`
  display: flex;
  padding: 60px 160px;
  gap: 40px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  font-size: 20px;
  color: black;
`;
const ViewAll = styled.div`
  font-size: 12px;
  color: blue;
`;
const Text1 = styled.div`
  font-size: 14px;
  color: gray;
  font-weight: 700;
`;
const Text2 = styled.div`
  font-size: 11px;
  color: gray;
`;
const Text3 = styled.div`
  font-size: 16px;
  color: gray;
`;
const Text4 = styled.div`
  font-size: 14px;
  color: gray;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 16px;
`;

const LeftDiv = styled.div`
  width: calc(80% - 40px);
`;

const Span1 = styled.div`
  font-size: 14px;
  color: gray;
`;
const Span2 = styled.div`
  font-size: 16px;
  color: gray;
  font-weight: 700;
`;
const Span3 = styled.div`
  font-size: 18px;
  color: black;
  font-weight: 700;
`;
const Span4 = styled.div`
  font-size: 16px;
  color: gray;
`;
const RightDiv = styled.div`
  width: calc(20%);
`;
const Overview = () => {
  const items = [
    {
      key: "1",
      label: (
        <FlexDiv>
          <div>Resources</div>
          <div
            style={{
              backgroundColor: "lightblue",
              padding: "1px 8px",
              marginLeft: 10,
            }}
          >
            0
          </div>
        </FlexDiv>
      ),
      children: (
        <Text4>
          <FlexDiv>
            <div>This workspace does not have any outputs</div>
            <div style={{ color: "blue" }}>Learn about outputs.</div>
          </FlexDiv>
        </Text4>
      ),
    },
    {
      key: "2",
      label: (
        <FlexDiv>
          <div>Outputs</div>
          <div
            style={{
              backgroundColor: "lightblue",
              padding: "1px 8px",
              marginLeft: 10,
            }}
          >
            0
          </div>
        </FlexDiv>
      ),
      children: (
        <Text4>
          <FlexDiv>
            <div>This workspace does not have any resources</div>
            <div style={{ color: "blue" }}>Learn about resources.</div>
          </FlexDiv>
        </Text4>
      ),
    },
  ];
  return (
    <RunsWrapper>
      <LeftDiv>
        <FlexDiv>
          <HeaderText>Latest Run</HeaderText>
          <ViewAll>View all runs</ViewAll>
        </FlexDiv>
        <div
          style={{
            border: "1px solid gray",
            padding: "12px 24px",
            marginTop: 20,
            marginBottom: 50,
          }}
        >
          <FlexDiv>
            <Text3>Triggered via UI</Text3>
            <Button style={{ backgroundColor: "green", color: "white" }}>
              <CheckOutlined /> Applied
            </Button>
          </FlexDiv>
          <Text1 style={{ marginBottom: 20 }}>
            <b>alexey-digger</b> triggered a{" "}
            <span style={{ color: "red" }}>destroy run </span>5 months ago via
            UI 65f89be
          </Text1>
          <FlexDiv>
            <FlexDiv style={{ gap: 30 }}>
              <div>
                <Text2 style={{ marginBottom: 4 }}>Policy checks</Text2>
                <Text1>Upgrade</Text1>
              </div>
              <div>
                <Text2 style={{ marginBottom: 4 }}>Estimated cost change</Text2>
                <Text1>Upgrade</Text1>
              </div>
              <div>
                <Text2 style={{ marginBottom: 4 }}>Plan & apply duration</Text2>
                <Text1>Less than a minute</Text1>
              </div>
              <div>
                <Text2 style={{ marginBottom: 4 }}>Resources changed</Text2>
                <Text1>
                  <span style={{ color: "green" }}>+0</span>
                  <span style={{ color: "blue" }}>~0</span>
                  <span style={{ color: "red" }}>-0</span>
                </Text1>
              </div>
            </FlexDiv>
            <Button style={{ color: "violet" }}>See details</Button>
          </FlexDiv>
        </div>
        <Tabs items={items} />
        <HeaderText
          style={{
            paddingBottom: 20,
            paddingTop: 50,
            borderBottom: "1px solid gray",
            borderTop: "1px solid gray",
            marginBottom: 15,
          }}
        >
          README.md
        </HeaderText>
        <Text4>
          Provide additional context by adding a README.md file to the branch
          and working directory of this workspace.
        </Text4>
      </LeftDiv>
      <RightDiv>
        <div style={{ borderBottom: "1px solid gray", paddingBottom: 30 }}>
          <Span1 style={{ color: "blue" }}>diggerthq/digger-tf</Span1>
          <Span1>
            Execution mote: <span style={{ color: "blue" }}>Remote</span>
          </Span1>
          <Span1>
            Auto apply: <span style={{ color: "blue" }}>Off</span>
          </Span1>
          <Span1>Project: Default Project</Span1>
        </div>
        <div
          style={{
            paddingTop: 30,
            paddingBottom: 50,
            borderBottom: "1px solid gray",
            display: "flex",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <Span3>
            Metrics{" "}
            <span style={{ fontWeight: 400, color: "gray" }}>
              (last 30 runs)
            </span>
          </Span3>
          <FlexDiv>
            <Span2>Average plan duration</Span2>
            <Span4>&lt;1min</Span4>
          </FlexDiv>
          <FlexDiv>
            <Span2>Average apply duration</Span2>
            <Span4>&lt;1min</Span4>
          </FlexDiv>
          <FlexDiv>
            <Span2>Total failed runs</Span2>
            <Span4>13</Span4>
          </FlexDiv>
          <FlexDiv>
            <Span2>Policy check failures</Span2>
            <Span4 style={{ color: "blue" }}>Upgrade</Span4>
          </FlexDiv>
        </div>
        <Span3 style={{ marginTop: 30, marginBottom: 20 }}>
          Tags <span style={{ fontWeight: 400, color: "gray" }}>0</span>
        </Span3>
        <Select placeholder="Add a tag" style={{ width: "100%" }} />
        <Text4 style={{ marginTop: 15, marginBottom: 30 }}>
          Tags have not been added to this <br />
          workspace.
        </Text4>
        <Span3 style={{ paddingTop: 30, borderTop: "1px solid gray" }}>
          Run triggers
        </Span3>
      </RightDiv>
    </RunsWrapper>
  );
};

export default Overview;
