import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const ViewDetailWrapper = styled.div`
  padding: 20px 0;
`;

const Title = styled.div`
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px 20px 20px;
`;

const code = `Terraform will perform the following actions:
    # null_resource.test_prod will be created
    + resource "null_resource" "test_prod" {
        + id = (known after apply)
    }

    # null_resource.test_prod2232 will be created
    + resource "null_resource" "test_prod2232" {
        + id = (known after apply)
    }
Plan: 2 to add, 0 to change, 0 to destroy.
`;

const ViewDetail = () => {
  const location =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  return (
    <ViewDetailWrapper>
      <Title>View {location}</Title>
      <pre
        style={{
          margin: "0 20px",
          backgroundColor: "black",
          padding: "20px",
          color: "white",
          width: "fit-content",
          borderRadius: "0.5rem",
          overflow: "auto",
          fontSize: 16,
        }}
      >
        {code}
      </pre>
    </ViewDetailWrapper>
  );
};

export default ViewDetail;
