import React from "react";
import styled from "styled-components";
import {
  CheckOutlined,
  ClockCircleOutlined,
  RocketOutlined,
  DashOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Button, Tag, Tabs, Input, Radio, Timeline } from "antd";
import { useNavigate } from "react-router-dom";
const { Search } = Input;

const RunListWrapper = styled.div`
  padding: 20px 0;
`;

const Title = styled.div`
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px 20px 20px;
`;
const Body = styled.div`
  background-color: #f9fafb;
  padding: 20px;
`;
const RunItem = styled.div`
  background-color: white;
  border: 1px solid #e5e6eb;
`;
const RunItemHeader = styled.div`
  color: black;
  padding: 0 12px;
  font-size: 28px;
  cursor: pointer;
`;
const RunItemMain = styled.div`
  color: gray;
  font-size: 16px;
  display: flex;
  padding: 0 12px 12px 12px;
  gap: 8px;
  align-items: center;
`;
const RunItemFooter = styled.div`
  border-top: 1px solid #e5e6eb;
  color: black;
  font-size: 20px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  display: flex;
  padding: 12px;
  align-items: center;
`;
const RunItemStatus = styled(Button)`
  display: flex;
  align-items: center;
  color: white;
  gap: 4px;
  font-size: 16px;
  padding: 4px 16px;
  background-color: #18bc80;
`;
const GreenNum = styled.div`
  color: #0bb878;
`;
const RedNum = styled.div`
  color: #e31d1e;
`;
const VersionNum = styled.div`
  color: #6f717b;
`;
const NumDiv = styled.div`
  float: right;
  display: flex;
  gap: 40px;
`;
const RenderRunItem = () => {
  const navigate = useNavigate();
  return (
    <RunItem>
      <RunItemHeader
        onClick={() => {
          navigate("5b78639");
        }}
      >
        Update main.tf
      </RunItemHeader>
      <RunItemMain>
        <ClockCircleOutlined />
        <div>
          Committed 5 days ago by lgor Zalutski | <CheckCircleFilled /> 5b78639
        </div>
      </RunItemMain>
      <RunItemFooter>
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <RunItemStatus>
            <CheckOutlined />
            FINISHED
          </RunItemStatus>
          <DashOutlined />
          finished 5 days ago, started by a Git commit
        </div>
        <NumDiv>
          <GreenNum>+1</GreenNum>
          <RedNum>-4</RedNum>
          <VersionNum>
            <RocketOutlined /> 1.5.3
          </VersionNum>
        </NumDiv>
      </RunItemFooter>
    </RunItem>
  );
};

const RunList = () => {
  return (
    <RunListWrapper>
      <Title>Run List</Title>
      <Body>
        <Timeline
          items={[
            {
              children: <RenderRunItem />,
            },
            {
              children: <RenderRunItem />,
            },
            {
              children: <RenderRunItem />,
            },
          ]}
        />
      </Body>
    </RunListWrapper>
  );
};

export default RunList;
