import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

import Projects from "./Pages/Projects";
import Overview from "./Pages/Overview";
import Runs from "./Pages/Runs";
import ViewDetail from "./Pages/ViewDetail";
import RunView from "./Pages/RunView";
import States from "./Pages/States";
import Variables from "./Pages/Variables";

const AppWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 10px; /* For vertical scrollbars */
    height: 10px; /* For horizontal scrollbars */
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  font-family: "Roboto", sans-serif !important;
`;
const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: #001529;
  color: white;
`;
const MainWrapper = styled.div`
  position: fixed;
  left: 300px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  width: calc(100vw - 300px);
`;

function HomePage() {
  let navigate = useNavigate();

  React.useEffect(() => {
    navigate("/digger/projects");
  }, [navigate]);

  return null;
}

const App = () => {
  return (
    <AppWrapper>
      <BrowserRouter>
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
        <MainWrapper>
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/digger/projects" element={<Projects />} />
            <Route
              exact
              path="/digger/projects/:name/overview"
              element={<Overview />}
            />
            <Route
              exact
              path="/digger/projects/:name/states"
              element={<States />}
            />
            <Route
              exact
              path="/digger/projects/:name/runs"
              element={<Runs />}
            />
            <Route
              exact
              path="/digger/projects/:name/runs/:runId"
              element={<RunView />}
            />
            <Route
              exact
              path="/digger/projects/:name/runs/:runId/view/:category"
              element={<ViewDetail />}
            />
            <Route
              exact
              path="/digger/projects/:name/variables"
              element={<Variables />}
            />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </MainWrapper>
      </BrowserRouter>
    </AppWrapper>
  );
};

export default App;
