import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Timeline, Button, Collapse } from "antd";
import {
  PlayCircleFilled,
  ClockCircleFilled,
  SettingFilled,
  EditFilled,
  CheckCircleFilled,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";

const RunViewWrapper = styled.div`
  padding: 20px 0;
`;

const Title = styled.div`
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding: 0 20px 20px 20px;
`;
const Name = styled.span`
  color: grey;
  text-decoration: underline;
  font-size: 20px;
`;
const Body = styled.div`
  padding: 20px 40px;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f5f7;
  padding-bottom: 8px;
`;
const Status = styled.div`
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const TimeAgo = styled.div`
  color: #aeb0b6;
`;

const MyCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-left: 0 !important;
  }
  .ant-collapse-expand-icon {
    display: none !important;
  }
`;

const RunView = () => {
  const location =
    useLocation().pathname.split("/")[
      useLocation().pathname.split("/").length - 1
    ];
  const navigate = useNavigate();
  const FinishedItem = () => (
    <ItemWrapper>
      <Status>
        Finished<TimeAgo> | 5 days ago</TimeAgo>
      </Status>
      <Button
        onClick={() => {
          navigate("view/execution");
        }}
      >
        View Execution
      </Button>
    </ItemWrapper>
  );
  const ApplyingItem = () => (
    <MyCollapse
      ghost
      items={[
        {
          key: "1",
          label: (
            <ItemWrapper style={{ alignItems: "flex-start" }}>
              <div>
                <Status>
                  Applying
                  <TimeAgo> | 00:02 | Auto deploy is on | 5 days ago</TimeAgo>
                </Status>
              </div>
              <div style={{ display: "flex", gap: 20 }}>
                <Button>View Policy</Button>
                <Button
                  onClick={() => {
                    navigate("view/execution");
                  }}
                >
                  View Execution
                </Button>
              </div>
            </ItemWrapper>
          ),
          children: (
            <>
              <div>
                - Allowed Resource Types{" "}
                <CloseOutlined style={{ color: "red" }} />
              </div>

              <div>
                - Password Strength <CheckOutlined style={{ color: "green" }} />
              </div>
            </>
          ),
        },
      ]}
    />
  );
  const PlanningItem = () => (
    <ItemWrapper>
      <Status>
        Planning<TimeAgo> | 00:03 | 5 days ago</TimeAgo>
      </Status>
      <div style={{ display: "flex", gap: 20 }}>
        <Button
          onClick={() => {
            navigate("view/plan");
          }}
        >
          View Plan
        </Button>
        <Button
          onClick={() => {
            navigate("view/execution");
          }}
        >
          View Execution
        </Button>
      </div>
    </ItemWrapper>
  );
  const InitializingItem = () => (
    <ItemWrapper>
      <Status>
        Initializing<TimeAgo> | 00:03 | 5 days ago</TimeAgo>
      </Status>
      <Button
        onClick={() => {
          navigate("view/execution");
        }}
      >
        View Execution
      </Button>
    </ItemWrapper>
  );
  const PreparingItem = () => (
    <ItemWrapper>
      <Status>
        Preparing<TimeAgo> | 00:03 | 5 days ago</TimeAgo>
      </Status>
      <Button
        onClick={() => {
          navigate("view/execution");
        }}
      >
        View Execution
      </Button>
    </ItemWrapper>
  );
  return (
    <RunViewWrapper>
      <Title>
        Run View <Name>({location})</Name>
      </Title>
      <Body>
        <Timeline
          items={[
            {
              color: "#16BD81",
              dot: <CheckCircleFilled style={{ fontSize: 20 }} />,
              children: <FinishedItem />,
            },
            {
              color: "#0975F1",
              dot: <PlayCircleFilled style={{ fontSize: 20 }} />,
              children: <ApplyingItem />,
            },
            {
              color: "#0B74F2",
              dot: <EditFilled style={{ fontSize: 20 }} />,
              children: <PlanningItem />,
            },
            {
              color: "#A6C3D2",
              dot: <SettingFilled style={{ fontSize: 20 }} />,
              children: <InitializingItem />,
            },
            {
              color: "#D1D2D8",
              dot: <ClockCircleFilled style={{ fontSize: 20 }} />,
              children: <PreparingItem />,
            },
          ]}
        />
      </Body>
    </RunViewWrapper>
  );
};

export default RunView;
