import React from "react";
import { useLocation } from "react-router-dom";
import { Select } from "antd";
import styled from "styled-components";

const HeaderWrapper = styled.div``;
const HeaderSpan = styled.span`
  text-transform: capitalize;
`;

const BreadCrumb = styled.div`
  padding: 16px 60px;
  color: black;
`;
const MainWrapper = styled.div`
  padding: 20px 60px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Text1 = styled.div`
  font-weight: 700;
  font-size: 36px;
`;
const Text2 = styled.div`
  font-size: 12px;
`;
const Text3 = styled.div`
  font-size: 16px;
`;

const Main = (location) => {
  if (location === "/digger/projects") {
    return;
  } else {
    return (
      <MainWrapper>
        <FlexDiv style={{ justifyContent: "space-between" }}>
          <Text1>digger-terraform</Text1>
          <FlexDiv style={{ gap: 120 }}>
            <div>
              <Text2>Resources</Text2>
              <Text3>0</Text3>
            </div>
            <div>
              <Text2>Terraform version</Text2>
              <Text3 style={{ color: "blue" }}>1.2.4</Text3>
            </div>
            <div>
              <Text2>Updated</Text2>
              <Text3>5 months ago</Text3>
            </div>
          </FlexDiv>
        </FlexDiv>
        <Text3 style={{ marginTop: 8 }}>ID: ws-dwT9Qcu2PCJUeDU5</Text3>
        <Text3 style={{ margin: "12px 0" }}>
          infrastructure for https://staging.diggerdev.xyz/
        </Text3>
        <FlexDiv style={{ justifyContent: "space-between" }}>
          <div>Unlocked</div>
          <Select
            defaultValue={"actions"}
            options={[
              {
                value: "actions",
                label: "Actions",
              },
              {
                value: "other",
                label: "Other",
              },
            ]}
          />
        </FlexDiv>
      </MainWrapper>
    );
  }
};
const Header = () => {
  const location = useLocation().pathname;
  const lastLocation = location.split("/");
  return (
    <HeaderWrapper>
      <BreadCrumb>
        {lastLocation.map((path, index) => {
          if (path !== "") {
            if (index !== lastLocation.length - 1)
              return (
                <HeaderSpan key={`header-span-${index}`}>{path} / </HeaderSpan>
              );
            else
              return (
                <HeaderSpan
                  style={{ color: "grey" }}
                  key={`header-span-${index}`}
                >
                  {path} /{" "}
                </HeaderSpan>
              );
          } else {
            return <div></div>;
          }
        })}
      </BreadCrumb>
      {Main(location)}
    </HeaderWrapper>
  );
};

export default Header;
