import {
  ArrowDownOutlined,
  ArrowsAltOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  WarningOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined,
  DownOutlined,
  TagsOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Table, Button, Input, Tag, Divider, Radio, Dropdown } from "antd";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HandleSpan = styled.span`
  cursor: pointer;
  color: black;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Span1 = styled.span`
  color: black;
  font-size: 20px;
`;
const Span2 = styled.span`
  color: gray;
  font-size: 16px;
`;
const columns = [
  {
    title: (
      <span>
        {" "}
        Workspace Name <ArrowDownOutlined />
      </span>
    ),
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Run Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Repo",
    dataIndex: "repo",
    key: "repo",
  },
  {
    title: (
      <span>
        {" "}
        Latest Change <ArrowsAltOutlined />
      </span>
    ),
    dataIndex: "change",
    key: "change",
  },
  {
    title: "",
    key: "other",
    dataIndex: "other",
  },
];
const data = [
  {
    key: "1",
    name: (
      <Link to="/digger/projects/digger-terraform/overview">
        <HandleSpan>digger-dev</HandleSpan>
      </Link>
    ),
    status: (
      <span style={{ cursor: "pointer" }}>
        <Tag color={"red"}>
          <span>
            <CloseOutlined /> Errored
          </span>
        </Tag>
      </span>
    ),
    repo: <HandleSpan>diggerhq/digger-tf</HandleSpan>,
    change: "5 months ago",
    other: "...",
  },
  {
    key: "2",
    name: "digger-staging",
    status: (
      <span>
        <Tag color={"green"}>
          <span>
            <CheckOutlined /> Succeeded
          </span>
        </Tag>
      </span>
    ),
    repo: "diggerhq/digger-tf",
    change: "5 months ago",
  },
  {
    key: "1",
    name: "digger-prod",
    status: (
      <span>
        <Tag color={"green"}>
          <span>
            <CheckOutlined /> Succeeded
          </span>
        </Tag>
      </span>
    ),
    repo: "diggerhq/digger-tf",
    change: "4 months ago",
  },
];

const Projects = () => {
  const items = [
    {
      key: "1",
      label: <div>Tag</div>,
    },
  ];
  return (
    <div style={{ padding: "20px 40px", borderTop: "1px solid gray" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ fontWeight: "bold", textAlign: "left" }}>Projects</h1>
        <Button type="primary" style={{ textAlign: "right" }}>
          New
        </Button>
      </div>
      <Divider></Divider>
      <FlexDiv
        style={{
          padding: "4px 12px",
          backgroundColor: "rgba(0,0,0,0.02)",
          borderTop: "1px solid lightgray",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Span1>Workspaces </Span1>
          <Span2>in Default Project</Span2>
        </div>
        <Input
          placeholder="Search Workspaces"
          style={{ width: 240 }}
          prefix={<SearchOutlined />}
        />
      </FlexDiv>
      <FlexDiv
        style={{
          padding: "8px 12px",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderTop: "1px solid lightgray",
          borderBottom: "1px solid lightgray",
        }}
      >
        <div>
          <Radio.Group>
            <Radio.Button>
              <WarningOutlined style={{ color: "orange" }} /> 0
            </Radio.Button>
            <Radio.Button>
              <CloseSquareOutlined style={{ color: "red" }} /> 1
            </Radio.Button>
            <Radio.Button>
              <InfoCircleOutlined style={{ color: "blue" }} /> 0
            </Radio.Button>
            <Radio.Button>
              <PauseCircleOutlined style={{ color: "black" }} /> 0
            </Radio.Button>
            <Radio.Button>
              <CheckCircleOutlined style={{ color: "green" }} /> 1
            </Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div>
              <TagsOutlined /> Tags <DownOutlined />
            </div>
          </Dropdown>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <div>
              <PaperClipOutlined /> Status <DownOutlined />
            </div>
          </Dropdown>
          <Button type="text">
            <CloseOutlined /> Clear all
          </Button>
        </div>
      </FlexDiv>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
};
export default Projects;
